import React, { memo } from "react";

// Libraries
import { Col, Container, Row } from "react-bootstrap";
import { PropTypes } from "prop-types";

// Components
import { Footer } from "./Footer";
import { useNavigate } from "react-router-dom";
import pmgIcon from "../../Assets/img/pmgicontransparent.png";
// Data
import FooterData from "./FooterData";

const FooterStyle01 = (props) => {
  const navigate = useNavigate();
  return (
    <Footer
      theme={props.theme}
      className={`${props.className ? ` ${props.className}` : ""}`}
    >
      <div className="py-[5%] lg:py-[95px] md:py-[50px]">
        <Container>
          <Row>
            <Col lg={4} sm={4}>
              <img src={pmgIcon} />
            </Col>
            <Col lg={4} sm={4} className="xs:mb-[25px]">
              <span
                style={{ fontSize: 22 }}
                className="mb-[20px] font-serif block font-semibold font-medium text-themecolor xs:mb-[10px]"
              >
                Get in touch
              </span>

              <a
                style={{ fontSize: 17 }}
                className="w-[85%] mb-[15px] font-semibold "
              >
                8 The Green STE 11673, Dover Delaware, 19904
              </a>

              <div style={{ fontSize: 17 }} className="font-semibold">
                <i className="feather-phone-call font-semibold text-sm mr-[10px] text-themecolor font-semibold "></i>
                +1 213 992 9337
              </div>
              <div style={{ fontSize: 17 }} className="font-semibold">
                <i className="feather-mail text-sm mr-[10px] text-themecolor font-semibold "></i>
                <a aria-label="mail" href="mailTo:info@yourdomain.com">
                  cs@pacificmovinggroup.com
                </a>
              </div>
            </Col>
            <Col lg={4} sm={4} className="xs:mb-[25px]">
              <span
                style={{ fontSize: 22 }}
                className="mb-[20px] font-serif block font-semibold font-medium text-themecolor xs:mb-[10px]"
              >
                Quick Links
              </span>
              <div style={{ fontSize: 17 }}>
                <a
                  className="w-[85%] mb-[15px] font-semibold "
                  onClick={() => navigate("/")}
                >
                  Get a Quote
                </a>
              </div>
              <div style={{ fontSize: 17 }}>
                <a
                  className="w-[85%] mb-[15px] font-semibold "
                  onClick={() => navigate("/services/long-distance")}
                >
                  Services
                </a>
              </div>
              <div style={{ fontSize: 17 }}>
                <a
                  className="w-[85%] mb-[15px] font-semibold text-large"
                  onClick={() => navigate("/about-us")}
                >
                  About Us
                </a>
              </div>
              <div style={{ fontSize: 17 }}>
                <a
                  className="w-[85%] mb-[15px] font-semibold "
                  onClick={() => navigate("/contact-us")}
                >
                  Contact
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Footer>
  );
};

FooterStyle01.defaultProps = {
  data: FooterData,
  className: "bg-darkgray text-[#828282]",
  logo: "/assets/img/webp/logo-white.webp",
  theme: "light",
};

FooterStyle01.propTypes = {
  className: PropTypes.string,
  logo: PropTypes.string,
};

export default memo(FooterStyle01);
