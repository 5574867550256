const HeaderData = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "About",
    link: "/about-us",
  },
  {
    title: "Services",
    link: "/services/long-distance",
  },
  {
    title: "Pay",
    link: "/pay-deposit",
  },

  {
    title: "Contact",
    link: "/contact-us",
  },
];

export default HeaderData;
