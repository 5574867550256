import candice from "../../Assets/img/candice.jpg";
import matt from "../../Assets/img/mattwalker.jpg";
import alex from "../../Assets/img/alexanderEsp.jpg";
const TestimonialsData01 = [
  {
    name: "SHOKO MUGIKURA",
    designation: "GRAPHIC DESIGNER",
    content:
      "Their team are easy to work with and helped me make amazing websites in a short amount of time. Thanks guys for all your hard work.",
    img: "https://via.placeholder.com/125x125",
  },
  {
    name: "JONSAN DONNER",
    designation: "SALES MANAGER",
    content:
      "Trust us we looked for a very long time and wasted thousands of dollars testing other teams, freelancers, and outsource companies.",
    img: "https://via.placeholder.com/125x125",
  },
  {
    name: "MACKANGY ROSE",
    designation: "CREATIVE DIRECTOR",
    content:
      "This is an excellent company! I personally enjoyed the energy and the professional support the whole team gave to us into creating website.",
    img: "https://via.placeholder.com/125x125",
  },
];

const TestimonialsData02 = [
  {
    name: "JEREMY SMITH",
    designation: "CO FOUNDER",
    content:
      "Lorem ipsum dolor amet consectetur do eiusmod tempor incididunt labore ut enim ad minim veniam",
    img: "https://via.placeholder.com/800x580",
  },
  {
    name: "SHOKO MUGIKURA",
    designation: "CREATIVE HEAD",
    content:
      "Lorem ipsum dolor amet consectetur do eiusmod tempor incididunt labore ut enim ad minim veniam",
    img: "https://via.placeholder.com/800x580",
  },
  {
    name: "HERMAN MILLER",
    designation: "HR MANAGER",
    content:
      "Lorem ipsum dolor amet consectetur do eiusmod tempor incididunt labore ut enim ad minim veniam",
    img: "https://via.placeholder.com/800x580",
  },
];
const TestimonialsData03 = [
  {
    name: "- Matt W     ",
    designation: "Austin, Texas",
    content:
      '"I\'ve moved a couple of times in my life but this was by far the quickest. They arrived within a week and even packed everything for me."',
    img: matt,
  },
  {
    name: "- Candice H     ",
    designation: "Lafayette, Louisiana",
    content:
      '"They really do care not to break anything or lose anything. All my pictures were completely intact just like I asked them."',
    img: candice,
  },
  {
    name: "- Alexander E       ",
    designation: "San Fransisco, California",
    content:
      '"I really wasnt expecting to get such a low price for this move. I moved from CA all the way to FL and after looking around, Pacific was by far the cheapest."',
    img: alex,
  },
];

const TestimonialsData04 = [
  {
    name: "Herman Miller",
    content:
      "Lorem ipsum dolor amet consectetur do tempor incididunt labore et dolore magna nostrud exercitation ullamco.",
    img: "https://via.placeholder.com/125x125",
    rating: 5,
  },
  {
    name: "Jeremy Girard",
    content:
      "Lorem ipsum dolor amet consectetur do tempor incididunt labore et dolore magna nostrud exercitation ullamco.",
    img: "https://via.placeholder.com/125x125",
    rating: 5,
  },
  {
    name: "Alexander Harvard",
    content:
      "Lorem ipsum dolor amet consectetur do tempor incididunt labore et dolore magna nostrud exercitation ullamco.",
    img: "https://via.placeholder.com/125x125",
    rating: 5,
  },
];

const TestimonialsData05 = [
  {
    name: "Herman Miller",
    content:
      "Simply superb resort. Management and staff deserve special appreciation and thanks for hospitality.",
    img: "https://via.placeholder.com/125x125",
    rating: 5,
  },
  {
    name: "Loretta Smith",
    content:
      "We are back home now and feel that it is only right to write to you to express our warmest gratitude.",
    img: "https://via.placeholder.com/125x125",
    rating: 5,
  },
  {
    name: "Jeremy Girard",
    content:
      "Wonderful place to live for holidays. Can’t believe can have such beautiful environment to spend peaceful.",
    img: "https://via.placeholder.com/125x125",
    rating: 5,
  },
];

export {
  TestimonialsData01,
  TestimonialsData02,
  TestimonialsData03,
  TestimonialsData04,
  TestimonialsData05,
};
