import React, { useRef } from "react";

// Libraries
import { Col, Container, Navbar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import HeaderData from "../Components/Header/HeaderData";
import { Formik, Form } from "formik";
import { m } from "framer-motion";

// Component
import { Header, HeaderNav, Menu } from "../Components/Header/Header";
import { Checkbox, Input, TextArea } from "../Components/Form/Form";
import Buttons from "../Components/Button/Buttons";
import { ContactFormStyle03Schema } from "../Components/Form/FormSchema";
import MessageBox from "../Components/MessageBox/MessageBox";
import Overlap from "../Components/Overlap/Overlap";
import FooterStyle01 from "../Components/Footers/FooterStyle01";
import CustomModal from "../Components/CustomModal";
import { fadeIn } from "../Functions/GlobalAnimations";
import { resetForm, sendEmail } from "../Functions/Utilities";
import SideButtons from "../Components/SideButtons";

const ContactUs = (props) => {
  const form = useRef(null);

  return (
    <div style={props.style}>
      {/* Header Start */}
      <div
        style={{
          position: "fixed",
          top: "0",
          width: "100%",
          background: "white",
          textAlign: "center",
          zIndex: 1000, // To ensure it appears above all other content
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Optional: for a shadow effect
        }}
      >
        <Header>
          <Container fluid>
            <Row className="justify-between pl-[15px] lg:pr-[15px] items-center">
              <HeaderNav
                bg="white"
                theme="light"
                containerClass="!px-0"
                className="py-[0px] md:py-[18px] md:px-[15px] sm:px-0"
              >
                <Col className="md:flex md:justify-end md:px-[15px]">
                  <Navbar.Toggle
                    className="order-last md:ml-[17px]"
                    onClick={() => {
                      console.log("Clicked2!");
                    }}
                  >
                    <span className="navbar-toggler-line"></span>
                    <span className="navbar-toggler-line"></span>
                    <span className="navbar-toggler-line"></span>
                    <span className="navbar-toggler-line"></span>
                  </Navbar.Toggle>
                  <div className="hidden md:block">
                    <Navbar.Collapse
                      className="col-auto justify-center"
                      onClick={() => {
                        console.log("Clicked!");
                      }}
                    >
                      <Menu {...props} />
                    </Navbar.Collapse>
                  </div>
                  <div className="flex justify-between md:hidden col-lg-9 col-xl-8 ">
                    <Menu
                      data={HeaderData.slice(0, Math.floor(HeaderData.length))}
                    />
                  </div>
                </Col>

                <Col className="col-auto none sm:block text-end lg:px-0 hide-on-small-screen">
                  <span className="top-bar-contact-list border-l border-inherit	py-[9px] px-[18px] text-[13px] inline-block float-left">
                    <i className="feather-phone-call text-darkgray mr-[6px] text-md relative top-[1px]"></i>{" "}
                    213 992 9337
                  </span>
                  <span className="border-l border-inherit py-[9px] pl-[18px] text-[13px] inline-block float-left border-r-0 pr-0">
                    <i className="feather-map-pin text-darkgray mr-[6px] text-md relative top-[1px]"></i>{" "}
                    8 The Green STE 11673, Dover Delaware, 19904
                  </span>
                </Col>
              </HeaderNav>
            </Row>
          </Container>
        </Header>
      </div>

      {/* Header End */}
      <SideButtons />
      {/* Section Start */}
      <section className="bg-lightgray py-[40px] sm:py-[30px]"></section>
      {/* Section End */}

      {/* Section Start */}
      <section className="py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px]">
        <Container>
          <Row className="row-cols-1 row-cols-lg-4 row-cols-sm-2 md:gap-y-[50px] sm:gap-y-[30px] text-center">
            <Col>
              <i className="line-icon-Geo2-Love text-gradient bg-fastblue text-[32px] mb-[30px] sm:mb-[10px] inline-block md:mb-[15px]"></i>
              <div className="text-darkgray uppercase text-sm font-medium font-serif mb-[10px]">
                Pacific Moving
              </div>
              <p className="w-[75%] lg:w-[90%] md:w-[60%] sm:w-[75%] xs:w-full mx-auto">
                8 The Green STE 11673, Dover Delaware, 19904
              </p>
            </Col>
            <Col>
              <i className="line-icon-Headset text-gradient bg-fastblue text-[32px] mb-[30px] sm:mb-[10px] inline-block md:mb-[15px]"></i>
              <div className="text-darkgray uppercase text-sm font-medium font-serif mb-[10px]">
                Let's Talk
              </div>
              <p className="w-[70%] lg:w-full mx-auto">
                Phone: 1-213-992-9337
                <br />
                Fax: 1-213-992-9337
              </p>
            </Col>
            <Col style={{ padding: 0 }}>
              <i className="line-icon-Mail-Read text-gradient bg-fastblue text-[32px] mb-[30px] sm:mb-[10px] inline-block md:mb-[15px]"></i>
              <div className="text-darkgray uppercase text-sm font-medium font-serif mb-[10px]">
                E-MAIL US
              </div>
              <p className="w-[70%] lg:w-full mx-auto">
                cs@pacificmovinggroup.com
              </p>
            </Col>
            <Col>
              <i className="line-icon-Information text-gradient bg-fastblue text-[32px] mb-[30px] sm:mb-[10px] inline-block md:mb-[15px]"></i>
              <div className="text-darkgray uppercase text-sm font-medium font-serif mb-[10px]">
                CUSTOMER SERVICES
              </div>
              <p className="w-[75%] lg:w-full md:w-[60%] sm:w-[75%] mx-auto">
                Contact us today for assistance!
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <section
        className="pt-[160px] pb-[250px] lg:pt-[120px] md:pt-[95px] md:pb-[220px] sm:py-[80px] xs:py-[50px] relative bg-cover overflow-hidden bg-center bg-no-repeat bg-fixed lg:bg-local"
        style={{
          backgroundImage: `url(/assets/img/webp/newborn-moving.png)`,
        }}
      >
        <div className="absolute h-full w-full opacity-80 top-0 left-0 bg-gradient-to-tr from-[#0039e3] via-[#5e28dd] to-[#8600d4]"></div>
        {/* Modal Component Start */}
        <CustomModal.Wrapper className="flex relative h-full w-full items-center justify-center">
          <div className="w-[1020px] max-w-full relative rounded mx-auto">
            <div className="fit-video">
              <iframe
                width="100%"
                height="100%"
                className="shadow-[0_0_8px_rgba(0,0,0,0.06)]"
                controls
                src="https://www.youtube.com/embed/g0f_BRYJLJE?autoplay=1"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </CustomModal.Wrapper>
        {/* Modal Component End */}
      </section>
      {/* Section End */}

      {/* Section Start */}
      <section className="bg-lightgray pb-[130px] lg:pb-[90px] md:pb-[75px] sm:py-[50px]">
        <Container>
          <m.div {...fadeIn} transitionDelay={0.5}>
            <Overlap
              value={20}
              className="relative p-32 lg:p-[104px] md:p-[60px] sm:p-[55px] xs:px-[22px] xs:py-[44px] shadow-[0_0_30px_rgba(0,0,0,0.1)] bg-white"
            >
              <Row className="justify-center">
                <Col
                  xl={6}
                  lg={7}
                  className="col-12 text-center mb-[4.5rem] md:mb-12"
                >
                  <span className="font-serif mb-[5px] -tracking-[.5px] text-xmd block">
                    Fill out the form and we'll be in touch soon!
                  </span>
                  <h4 className="font-serif font-semibold text-darkgray">
                    How we can help you?
                  </h4>
                </Col>
                <Col className="col-12">
                  <Formik
                    initialValues={{
                      name: "",
                      email: "",
                      phone: "",
                      comment: "",
                      subject: "PMG Help Request",
                      terms_condition: false,
                    }}
                    validationSchema={ContactFormStyle03Schema}
                    onSubmit={async (values, actions) => {
                      console.log("Submmitted!");
                      actions.setSubmitting(true);
                      const response = await sendEmail(values);
                      response.status === "success" && resetForm(actions);
                    }}
                  >
                    {({ isSubmitting, status }) => (
                      <Form ref={form}>
                        <Row className="row-cols-1 row-cols-md-2">
                          <Col className="mb-16 sm:mb-[25px]">
                            <Input
                              showErrorMsg={false}
                              type="text"
                              name="name"
                              className="py-[15px] px-[20px] text-md w-full border-[1px] border-solid border-[#dfdfdf]"
                              labelClass="mb-[25px]"
                              placeholder="Your name"
                            />
                            <Input
                              showErrorMsg={false}
                              type="email"
                              name="email"
                              className="py-[15px] px-[20px] w-full text-md border-[1px] border-solid border-[#dfdfdf]"
                              labelClass="mb-[25px]"
                              placeholder="Your email address"
                            />
                            <Input
                              showErrorMsg={false}
                              type="tel"
                              name="phone"
                              className="py-[15px] px-[20px] w-full text-md border-[1px] border-solid border-[#dfdfdf]"
                              placeholder="Mobile number"
                            />
                          </Col>
                          <Col className="mb-16 sm:mb-[20px]">
                            <TextArea
                              className="border-[1px] border-solid border-[#dfdfdf] w-full py-[15px] px-[20px] text-md h-[210px] resize-none"
                              rows="6"
                              name="comment"
                              placeholder="Your message"
                            ></TextArea>
                          </Col>
                          <Col className="text-left sm:mb-[20px]">
                            <Checkbox
                              type="checkbox"
                              name="terms_condition"
                              className="inline-block mt-[4px]"
                              labelClass="flex items-start"
                            >
                              <span className="ml-[10px] text-sm inline-block w-[85%]">
                                I accept the terms & conditions and I understand
                                that my data will be held securely in accordance
                                with the
                                <Link
                                  aria-label="checkbox"
                                  to="/privacy"
                                  target="_blank"
                                  className="text-darkgray underline hover:text-fastblue"
                                >
                                  {" "}
                                  privacy policy
                                </Link>
                                .
                              </span>
                            </Checkbox>
                          </Col>
                          <Col className="text-right sm:text-center">
                            <Buttons
                              type="submit"
                              className={`text-xs tracking-[1px] rounded-none py-[12px] px-[28px] uppercase${
                                isSubmitting ? " loading" : ""
                              }`}
                              themeColor={["#b884fd", "#fe73a8", "b884fd"]}
                              size="md"
                              color="#fff"
                              title="Send Message"
                            />
                          </Col>
                        </Row>
                        <AnimatePresence>
                          {status && (
                            <Row>
                              <Col xs={12}>
                                <div
                                  initial={{ opacity: 0 }}
                                  animate={{ opacity: 1 }}
                                  exit={{ opacity: 0 }}
                                >
                                  <MessageBox
                                    className="mt-[20px] py-[10px]"
                                    theme="message-box01"
                                    variant="success"
                                    message="Your message has been sent successfully!"
                                  />
                                </div>
                              </Col>
                            </Row>
                          )}
                        </AnimatePresence>
                      </Form>
                    )}
                  </Formik>
                </Col>
              </Row>
            </Overlap>
          </m.div>
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <section className="py-[80px] md:py-[40px]">
        <Container>
          <Row className="items-center">
            <Col
              lg={6}
              md={7}
              sm={6}
              className="xl:text-start xs:text-center sm:my-[25px] xs:mb-[30px]"
            >
              <h6 className="font-serif text-darkgray font-medium mb-[10px]">
                Ready to start your move?
              </h6>
              <Buttons
                to="/"
                className="font-medium after:bg-fastblue !leading-[25px] font-serif uppercase btn-link after:h-[2px] md:text-md hover:opacity-50"
                color="#0038e3"
                size="xl"
                title="Book a quote"
              />
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Footer Start */}
      <FooterStyle01 theme="dark" className="bg-[#262b35] text-slateblue" />
      {/* Footer End */}
    </div>
  );
};

export default ContactUs;
