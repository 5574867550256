import React from "react";

// Libraries
import { Col, Container, Navbar, Row } from "react-bootstrap";
import { m } from "framer-motion";

// Components
import { Header, HeaderNav, Menu } from "../Components/Header/Header";
import HeaderData from "../Components/Header/HeaderData";
import { fadeIn } from "../Functions/GlobalAnimations";
import FooterStyle01 from "../Components/Footers/FooterStyle01";
import SideButtons from "../Components/SideButtons";

// Data
import { Paper } from "@mui/material";

const ServicesPage = (props) => {
  return (
    <div style={props.style}>
      <SideButtons />
      {/* Header Start */}
      <div
        style={{
          position: "fixed",
          top: "0",
          width: "100%",
          background: "white",
          textAlign: "center",
          zIndex: 1000, // To ensure it appears above all other content
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Optional: for a shadow effect
        }}
      >
        <Header>
          <Container fluid>
            <Row className="justify-between pl-[15px] lg:pr-[15px] items-center">
              <HeaderNav
                bg="white"
                theme="light"
                containerClass="!px-0"
                className="py-[0px] md:py-[18px] md:px-[15px] sm:px-0"
              >
                <Col className="md:flex md:justify-end md:px-[15px]">
                  <Navbar.Toggle
                    className="order-last md:ml-[17px]"
                    onClick={() => {
                      console.log("Clicked2!");
                    }}
                  >
                    <span className="navbar-toggler-line"></span>
                    <span className="navbar-toggler-line"></span>
                    <span className="navbar-toggler-line"></span>
                    <span className="navbar-toggler-line"></span>
                  </Navbar.Toggle>
                  <div className="hidden md:block">
                    <Navbar.Collapse
                      className="col-auto justify-center"
                      onClick={() => {
                        console.log("Clicked!");
                      }}
                    >
                      <Menu {...props} />
                    </Navbar.Collapse>
                  </div>
                  <div className="flex justify-between md:hidden col-lg-9 col-xl-8 ">
                    <Menu
                      data={HeaderData.slice(0, Math.floor(HeaderData.length))}
                    />
                  </div>
                </Col>

                <Col className="col-auto none sm:block text-end lg:px-0 hide-on-small-screen">
                  <span className="top-bar-contact-list border-l border-inherit	py-[9px] px-[18px] text-[13px] inline-block float-left">
                    <i className="feather-phone-call text-darkgray mr-[6px] text-md relative top-[1px]"></i>{" "}
                    213 992 9337
                  </span>
                  <span className="border-l border-inherit py-[9px] pl-[18px] text-[13px] inline-block float-left border-r-0 pr-0">
                    <i className="feather-map-pin text-darkgray mr-[6px] text-md relative top-[1px]"></i>{" "}
                    8 The Green STE 11673, Dover Delaware, 19904
                  </span>
                </Col>
              </HeaderNav>
            </Row>
          </Container>
        </Header>
      </div>

      {/* Header End */}

      {/* Section Start */}
      <section
        className="py-[130px] overflow-hidden lg:py-[90px] sm:py-[75px] xs:py-[50px]"
        style={{
          backgroundImage: `url('/assets/img/webp/bg2.png')`,
        }}
      >
        <Container>
          <Row className="items-center justify-center">
            <Col lg={5} md={12} className="mb-[10px] md:mb-28">
              <h4 className="font-serif font-semibold text-darkgray w-[95%]">
                Long Distance
              </h4>
              <Paper
                elevation={24}
                style={{
                  backgroundColor: "white",
                  padding: 30,
                  border: "blue 3px solid",
                }}
              >
                <p
                  className="w-[80%] mb-[25px] lg:w-[95%]"
                  style={{
                    textAlign: "left",
                    width: "100%",
                    fontSize: 18,
                    fontWeight: 900,
                  }}
                >
                  Pacific Moving Group goes the extra mile when it comes to
                  facilitating long-distance moves. We provide long-distance
                  moving solutions that meet all of your moving needs. You will
                  work with our team of specialists to make sure your move is
                  completed safely on time.
                </p>
                <p
                  className="w-[80%] mb-[25px] lg:w-[95%]"
                  style={{
                    textAlign: "left",
                    width: "100%",
                    fontSize: 18,
                    fontWeight: 900,
                  }}
                >
                  We understand that a long-distance move is a stressful and
                  detailed process. We complete the journey of your move with
                  you, providing a consistent, high level of service and
                  professionalism from the first phone call or on-site estimate
                  all the way to your delivery.
                </p>
              </Paper>
            </Col>
            <Col
              lg={7}
              md={10}
              xs={12}
              className="px-[55px] md:pr-[50px] md:pl-[5px]"
            >
              <m.figure className="image-back-offset-shadow w-100">
                <m.img
                  className="rounded-[6px] w-auto h-auto"
                  src="/assets/img/webp/movingvan.jpeg"
                  alt="our-services"
                  width="572.5"
                  height="436.53"
                  initial={{ opacity: 0, x: "0", y: "0" }}
                  whileInView={{ opacity: 1, x: "10px", y: "-20px" }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.4, easing: "linear" }}
                  style={{ border: "blue 6px solid" }}
                />
              </m.figure>
            </Col>
          </Row>
          <Row
            className="items-center justify-center"
            style={{ paddingTop: 80 }}
          >
            <Col lg={5} md={12} className="mb-[10px] md:mb-28">
              <h4 className="font-serif font-semibold text-darkgray w-[95%]">
                Local Moving
              </h4>
              <Paper
                elevation={24}
                style={{
                  backgroundColor: "white",
                  padding: 30,
                  border: "blue 3px solid",
                }}
              >
                <p
                  className="w-[80%] mb-[25px] lg:w-[95%]"
                  style={{
                    textAlign: "left",
                    width: "100%",
                    fontSize: 18,
                    fontWeight: 900,
                  }}
                >
                  When it comes to hiring moving companies, Pacific Moving Group
                  has you covered. We show up to our moves with the proper
                  equipment and best practices to get the job done right. Our
                  moving experts often live in the communities where they work,
                  so knowledge of the area plays to our advantage as we get you
                  moved!
                </p>
                <p
                  className="w-[80%] mb-[25px] lg:w-[95%]"
                  style={{
                    textAlign: "left",
                    width: "100%",
                    fontSize: 18,
                    fontWeight: 900,
                  }}
                >
                  Our movers will pad and stretch-wrap all of your belongings
                  for maximum protection during your move. Our professionally
                  trained move teams are background-checked and drug-screened,
                  giving you peace of mind that your items are safe throughout
                  the entire moving process. Worried about the cost of your
                  move? Let our customer service representatives walk you
                  through options to keep the costs down, while ensuring you
                  still get the same great moving service you can expect from
                  Pacific Moving Group.
                </p>
              </Paper>
            </Col>
            <Col
              lg={7}
              md={10}
              xs={12}
              className="px-[55px] md:pr-[50px] md:pl-[5px]"
            >
              <m.figure className="image-back-offset-shadow w-100">
                <m.img
                  className="rounded-[6px] w-auto h-auto"
                  src="/assets/img/webp/truck-city.jpeg"
                  alt="our-services"
                  width="572.5"
                  height="436.53"
                  initial={{ opacity: 0, x: "0", y: "0" }}
                  whileInView={{ opacity: 1, x: "10px", y: "-20px" }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.4, easing: "linear" }}
                  style={{ border: "blue 6px solid" }}
                />
              </m.figure>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <section
        className="bg-lightgray z-[1] relative py-[130px] lg:py-[90px] sm:py-[75px] xs:py-[50px]"
        style={{
          backgroundImage: `url('/assets/img/webp/bg2.png')`,
        }}
      >
        <Container></Container>
      </section>
      {/* Section End */}

      <m.section className="bg-[#262b35] sm:bg-lightgray" {...fadeIn}>
        {/* footer Start */}
        <FooterStyle01 theme="dark" className="text-slateblue bg-[#262b35]" />
        {/* footer End */}
      </m.section>
    </div>
  );
};

export default ServicesPage;
