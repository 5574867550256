import React, { lazy, useState } from "react";

// Libraries
import { Col, Container, Navbar, Row } from "react-bootstrap";
import { Paper } from "@mui/material";
import { m } from "framer-motion";
import pic1 from "../Assets/img/googlecustomerreview.png";
import FooterStyle01 from "../Components/Footers/FooterStyle01";
import pic2 from "../Assets/img/consumeraffairs.png";
import pic3 from "../Assets/img/trustpilot.png";
import pic4 from "../Assets/img/verifiedmovers.png";
// Components
import { fadeIn } from "../Functions/GlobalAnimations";
import { ContactFormStyle02Schema } from "../Components/Form/FormSchema";
import { Formik, Form } from "formik";
import { Input } from "../Components/Form/Form";
import { AnimatePresence } from "framer-motion";
import MessageBox from "../Components/MessageBox/MessageBox";
import { resetForm, sendEmail } from "../Functions/Utilities";
import pmgIcon from "../Assets/img/pmgicontransparent.png";

import Buttons from "../Components/Button/Buttons";
import Clients from "../Components/Clients/Clients";
import Testimonials from "../Components/Testimonials/Testimonials";
import Counter from "../Components/Counters/Counter";
import HeaderData from "../Components/Header/HeaderData";
import Header, { HeaderNav, Menu } from "../Components/Header/Header";
import SideButtons from "../Components/SideButtons";

// Data
import { TestimonialsData03 } from "../Components/Testimonials/TestimonialsData";
import InViewPort from "../Components/InViewPort";

function StarRating({ totalStars }) {
  const [selectedStars, setSelectedStars] = useState(5);

  return (
    <div style={{ display: "inline-block" }}>
      {[...Array(totalStars)].map((n, i) => (
        <Star key={i} selected={i < selectedStars} />
      ))}
    </div>
  );
}

function Star({ selected = false, onSelect = (f) => f }) {
  return (
    <div
      onClick={onSelect}
      style={{
        display: "inline-block",
        fontSize: "1.5rem",
        cursor: "pointer",
        color: selected ? "orange" : "gray",
      }}
    >
      ★
    </div>
  );
}

const IconWithText = lazy(() =>
  import("../Components/IconWithText/IconWithText")
);

const ClientData = [
  {
    img: pic1,
  },
  {
    img: pic2,
  },
  {
    img: pic3,
  },
  {
    img: pic4,
  },
];

const IconWithTextData = [
  {
    icon: "line-icon-Box-Close text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e] text-[40px]",
    title: "Packing & Unpacking",
    content: "Save time with our full, or partial packing services",
  },
  {
    icon: "line-icon-Safe-Box text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e] text-[40px] ",
    title: "Storage",
    content: "Full residential and commercial storage options are available",
  },
  {
    icon: "line-icon-Warehouse text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e] text-[40px]",
    title: "30 Days of Free Storage & Free Redelivery",
    content: "Long-term storage options available",
  },
  {
    icon: "line-icon-Jet text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e] text-[40px]",
    title: "Government & Military Moving",
    content:
      "We have the highest respect for those who serve our armed forces. Military and government employees receive a 15% discount",
  },
];

const CounterData = [
  {
    number: {
      text: "42553",
    },
    content: "Happy Customers to date",
  },
  {
    number: {
      text: "34882",
    },
    content: "Long Distance moves",
  },
  {
    number: {
      text: "7671",
    },
    content: "Short Distance moves",
  },
];

const HomePage = (props) => {
  return (
    <div style={props.style}>
      <SideButtons />

      {/* Section Start */}
      <m.section className="" {...fadeIn}>
        <Container>
          <Row style={{ backgroundColor: "" }}>
            <Col className="py-[40px] lg:py-[40px] md:py-[41px] sm:py-[25px] xs:py-[25px]"></Col>

            <p style={{ fontSize: 13, height: "22px" }}>
              <span className="font-serif font-semibold text-darkgray uppercase ">
                US DOT:
              </span>{" "}
              4118716{" "}
              <span className="font-serif font-semibold text-darkgray uppercase  ">
                MC:{" "}
              </span>
              1574802
            </p>
            <p style={{ fontSize: 15, height: "22px" }}>
              <StarRating totalStars={5} />{" "}
              <span className="font-serif font-semibold text-darkgray   ">
                <i className="feather-phone-call text-darkgray mr-[6px] text-md relative top-[1px]"></i>{" "}
                Rated
              </span>{" "}
              <span className="font-serif font-semibold text-darkgray   ">
                (213) 992 9337
              </span>
            </p>
            <p
              style={{
                fontSize: 13,
                height: "28px",
              }}
            >
              Licensed Moving Company
            </p>
            <Col className="mb-[0.2%]"></Col>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}

      {/* Header Start */}
      <div
        style={{
          position: "fixed",
          top: "0",
          width: "100%",
          background: "white",
          textAlign: "center",
          zIndex: 1000, // To ensure it appears above all other content
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Optional: for a shadow effect
        }}
      >
        <Header>
          <Container fluid>
            <Row className="justify-between pl-[15px] lg:pr-[15px] items-center">
              <HeaderNav
                bg="white"
                theme="light"
                containerClass="!px-0"
                className="py-[0px] md:py-[18px] md:px-[15px] sm:px-0"
              >
                <Col className="md:flex md:justify-end md:px-[15px]">
                  <Navbar.Toggle
                    className="order-last md:ml-[17px]"
                    onClick={() => {
                      console.log("Clicked2!");
                    }}
                  >
                    <span className="navbar-toggler-line"></span>
                    <span className="navbar-toggler-line"></span>
                    <span className="navbar-toggler-line"></span>
                    <span className="navbar-toggler-line"></span>
                  </Navbar.Toggle>
                  <div className="hidden md:block">
                    <Navbar.Collapse
                      className="col-auto justify-center"
                      onClick={() => {
                        console.log("Clicked!");
                      }}
                    >
                      <Menu {...props} />
                    </Navbar.Collapse>
                  </div>
                  <div className="flex justify-between md:hidden col-lg-9 col-xl-8 ">
                    <Menu
                      data={HeaderData.slice(0, Math.floor(HeaderData.length))}
                    />
                  </div>
                </Col>

                <Col className="col-auto none sm:block text-end lg:px-0 hide-on-small-screen">
                  <span className="border-l border-inherit py-[9px] pl-[18px] text-[13px] inline-block float-left border-r-0 pr-0">
                    <i className="feather-map-pin text-darkgray mr-[6px] text-md relative top-[1px]"></i>{" "}
                    8 The Green STE 11673, Dover Delaware, 19904
                  </span>
                </Col>
              </HeaderNav>
            </Row>
          </Container>
        </Header>
      </div>

      {/* Header End */}

      {/* Section Start */}
      <m.section
        className="py-[160px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px] bg-no-repeat"
        style={{
          backgroundImage: "url(/assets/img/webp/bg3.png)",
          backgroundSize: "cover",
        }}
        {...fadeIn}
      >
        <Container>
          <Row className="justify-center relative">
            <m.div
              className="flex flex-col md:mb-[50px] col-lg-5 col-md-8"
              {...fadeIn}
            >
              <img src={pmgIcon} />
              <div className="freelancer-social-icon mt-auto">
                <span className="font-serif font-semibold text-xmd text-darkgray uppercase block mb-[25px]">
                  Contact info
                </span>
                <p className="mb-0 font-sans text-black">
                  8 The Green STE 11673, Dover Delaware, 19904
                </p>
                <p className="font-sans text-black">
                  <span className="font-medium text-black">Tel:</span> (213) 992
                  9337{" "}
                </p>
                <p className="font-sans mb-[25px]">
                  <span className="font-medium text-black">Email:</span>
                  <a
                    aria-label="gamil"
                    href="mailto:cs@pacificmovinggroup.com"
                    className="hover:text-white text-black"
                  >
                    {" "}
                    cs@pacificmovinggroup.com
                  </a>
                </p>
              </div>
            </m.div>
            <m.div
              className="col-lg-6 col-md-8 offset-lg-1 overflow-hidden"
              {...{ ...fadeIn, transition: { delay: 0.5 } }}
            >
              <h2 className="heading-5 font-serif font-semibold text-darkgray mb-20 -tracking-[1px]">
                Receive a free quote!
              </h2>
              <Paper style={{ padding: "20px", border: "10px solid #5e49fc" }}>
                <Formik
                  initialValues={{
                    name: "",
                    bedrooms: "",
                    szip: "",
                    dzip: "",
                    email: "",
                    phone: "",
                    subject: "PMG Quote Request",
                  }}
                  validationSchema={ContactFormStyle02Schema}
                  onSubmit={async (values, actions) => {
                    console.log("Submmitted!");
                    actions.setSubmitting(true);
                    actions.resetForm();
                    const response = await sendEmail(values);
                    response.status === "success" && resetForm(actions);
                  }}
                >
                  {({ isSubmitting, status }) => (
                    <Form>
                      <Input
                        showErrorMsg={false}
                        type="text"
                        name="name"
                        labelClass="mb-[15px]"
                        className="pt-[18px] pb-[19px] w-full bg-transparent border-b border-solid border-[#ededed]"
                        placeholder="First Name"
                      />
                      <Input
                        showErrorMsg={false}
                        type="text"
                        name="bedrooms"
                        labelClass="mb-[15px]"
                        className="pt-[18px] pb-[19px] w-full bg-transparent border-b border-solid border-[#ededed]"
                        placeholder="Number of Bedrooms"
                      />{" "}
                      <Input
                        showErrorMsg={false}
                        type="text"
                        name="szip"
                        labelClass="mb-[15px]"
                        className="pt-[18px] pb-[19px] w-full bg-transparent border-b border-solid border-[#ededed]"
                        placeholder="Starting Zip Code"
                      />{" "}
                      <Input
                        showErrorMsg={false}
                        type="text"
                        name="dzip"
                        labelClass="mb-[15px]"
                        className="pt-[18px] pb-[19px] w-full bg-transparent border-b border-solid border-[#ededed]"
                        placeholder="Destination Zip Code"
                      />
                      <Input
                        showErrorMsg={false}
                        type="email"
                        name="email"
                        labelClass="mb-[15px]"
                        className="pt-[18px] pb-[19px] w-full bg-transparent border-b border-solid border-[#ededed]"
                        placeholder="Email address"
                      />
                      <Input
                        showErrorMsg={false}
                        type="tel"
                        name="phone"
                        labelClass="mb-[15px]"
                        className="pt-[18px] pb-[19px] w-full bg-transparent border-b border-solid border-[#ededed]"
                        placeholder="Phone number"
                      />
                      <Buttons
                        ariaLabel="Send Message"
                        type="submit"
                        className={`tracking-[1px] btn-fancy rounded-none font-medium uppercase${
                          isSubmitting ? " loading" : ""
                        }`}
                        themeColor={["#556fff", "#e05fc4", "#ff798e"]}
                        size="md"
                        color="#fff"
                        title="Get a Quote!"
                      />
                      <AnimatePresence>
                        {status && (
                          <m.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                          >
                            <MessageBox
                              className="mt-[20px] py-[10px]"
                              theme="message-box01"
                              variant="success"
                              message="Your message has been sent successfully!"
                            />
                          </m.div>
                        )}
                      </AnimatePresence>
                    </Form>
                  )}
                </Formik>
              </Paper>
            </m.div>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}

      {/* Section Start */}
      <section className="py-[100px] lg:py-[90px] md:py-[75px]">
        <Container className="text-center">
          <Counter
            as="h4"
            theme="counter-style-02"
            grid="row-cols-1 row-cols-md-4 row-cols-sm-2 gap-y-10 items-center justify-center gap-y-10"
            className="text-black text-[70px]"
            data={CounterData}
            duration={2}
            animation={fadeIn}
          />
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <section className="py-[90px] overflow-hidden lg:py-[60px] md:py-[40px] sm:py-[20px]">
        <Container>
          <Row className="items-center justify-center">
            <m.div
              {...fadeIn}
              className="col-8 col-lg-5 offset-lg-1 col-md-5 col-sm-6 md:mb-[50px]"
            >
              <img
                height="800"
                width="1000"
                src="/assets/img/webp/movingfun1.png"
                alt=""
              />
            </m.div>
            <m.div
              {...{ ...fadeIn, transition: { delay: 0.6 } }}
              className="col-12 col-lg-5 offset-lg-1 visible"
            >
              <h5 className="font-medium tracking-[-1px] text-darkgray font-serif mb-[25px] md:text-center lg:mb-[15px]">
                We Ship Long Distance and Locally
              </h5>
              <p className="mb-0 xl:text-start w-full md:w-[80%] lg:text-start lg:mx-auto md:text-center">
                We're a team of professional moving coordinators. Any distance,
                we will ship it. Get a price quote today for more details. We're
                completely licensed and insured. We pack, load, ship,
                disassemble, and reassemble. You won't have to lift a finger.
              </p>
            </m.div>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Lazy Load HTML */}
      <InViewPort>
        <hr />

        {/* Section Start */}
        <section
          className="py-[160px] overflow-hidden lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]"
          style={{
            backgroundImage: `url('/assets/img/webp/bg5.png')`,
            opacity: "0.9",
          }}
        >
          <Container>
            <Row className="justify-center">
              <m.div
                className="col-xl-3 col-lg-4 col-sm-7 flex flex-col md:mb-24"
                {...{ ...fadeIn, transition: { delay: 0.2 } }}
              >
                <div className="mb-[20px] md:text-center sm:mb-[10px]"></div>
                <h3 className="alt-font text-darkgray font-semibold mb-[20px] font-serif md:text-center md:mb-[30px] heading-5">
                  Here's what we have to offer...
                </h3>
              </m.div>
              <Col xl={{ span: 7, offset: 2 }} lg={8}>
                <IconWithText
                  grid="row-cols-1 row-cols-lg-2 row-cols-sm-2 gap-y-[40px]"
                  theme="icon-with-text-01"
                  data={IconWithTextData}
                  animation={fadeIn}
                  animationDelay={0.2}
                />
              </Col>
            </Row>
          </Container>
        </section>
        {/* Section End */}

        {/* Section Start */}
        <section className="bg-[#f7f8fc] py-[100px] lg:py-[90px] md:py-[75px]">
          <Container>
            <Row className="justify-center">
              <Col lg={11}>
                <Clients
                  grid="row-cols-2 row-cols-lg-6 row-cols-sm-3 md:gap-10 xs:gap-y-[50px] justify-between md:justify-center"
                  theme="client-logo-style-05"
                  data={ClientData}
                  animation={fadeIn}
                />
              </Col>
            </Row>
          </Container>
        </section>
        {/* Section End */}

        {/* Section Light Start */}
        <section
          className="py-[160px] lg:py-[50px] md:py-[35px] sm:py-[10px] xs:py-[10px]"
          style={{
            backgroundImage: `url('/assets/img/webp/bg2.png')`,
            opacity: "0.9",
          }}
        >
          <Container>
            <Row className="justify-center">
              <Col xs={12} sm={8} md={12}>
                <Testimonials
                  grid="row-cols-1 row-cols-md-2 row-cols-lg-3 gap-y-[50px] justify-center"
                  theme="testimonials-style-01"
                  className="sm:px-0"
                  data={TestimonialsData03}
                  animation={fadeIn}
                  animationDelay={0.2}
                />
              </Col>
            </Row>
          </Container>
        </section>
        {/* Section Light End */}

        <hr />

        {/* Footer Start */}
        <FooterStyle01 theme="dark" className="bg-[#262b35] text-slateblue" />
        {/* Footer End */}
      </InViewPort>
    </div>
  );
};

export default HomePage;
