import logo from "./logo.svg";
import "./App.css";
import React, { Suspense, useEffect, useState, lazy } from "react";
import Header from "./Components/Header/Header";
import { Col, Container, Navbar, Row } from "react-bootstrap";
import { Menu, HeaderNav } from "./Components/Header/Header";
import HeaderData from "./Components/Header/HeaderData";
import retina from "retinajs";
// Libraries
import { Routes, Route, useLocation } from "react-router-dom";
import MyComingSoon from "./MyComponents/MyComingSoon";
import HomePage from "./MyComponents/HomePage";
import AboutUs from "./MyComponents/AboutUs";
import ContactUs from "./MyComponents/ContactUs";
import PayDeposit from "./MyComponents/PayDeposit";
import NotFound from "./MyComponents/404Page";
import ServicesPage from "./MyComponents/ServicesPage";

import GlobalContext from "./Context/Context";

const App = (props) => {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false,
  });
  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    document.title = "Pacific Moving Group";
    window.addEventListener("load", retina(document.querySelectorAll("img")));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      import("./Functions/Utilities").then((module) => {
        module.SetHeaderMenuPos();
        module.setDocumentFullHeight();
      });
    }, 1000);
  }, [location]);

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/home/" element={<HomePage />} />
          <Route path="/pay-deposit/" element={<PayDeposit />} />
          <Route path="/about-us/" element={<AboutUs />} />
          <Route path="/contact-us/" element={<ContactUs />} />
          <Route path="/services/long-distance" element={<ServicesPage />} />
          <Route path="/services/short-distance/" element={<ServicesPage />} />
          <Route
            path="/services/professional-packing/"
            element={<ServicesPage />}
          />
          <Route Component={NotFound} />
        </Routes>
      </div>
    </GlobalContext.Provider>
  );
};

export default App;
