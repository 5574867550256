import React from "react";

// Components
import { Header, HeaderNav, Menu } from "../Components/Header/Header";
import HeaderData from "../Components/Header/HeaderData";
import { fadeIn } from "../Functions/GlobalAnimations";
import { IconWithTextData_02 } from "../Components/IconWithText/IconWithTextData";
import FooterStyle01 from "../Components/Footers/FooterStyle01";
import IconWithText from "../Components/IconWithText/IconWithText";
import SideButtons from "../Components/SideButtons";

// Libraries
import { Col, Container, Row, Navbar } from "react-bootstrap";
import { m } from "framer-motion";

const AboutUs = (props) => {
  return (
    <div style={props.style}>
      <SideButtons />
      {/* Header Start */}
      <div
        style={{
          position: "fixed",
          top: "0",
          width: "100%",
          background: "white",
          textAlign: "center",
          zIndex: 1000, // To ensure it appears above all other content
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Optional: for a shadow effect
        }}
      >
        <Header>
          <Container fluid>
            <Row className="justify-between pl-[15px] lg:pr-[15px] items-center">
              <HeaderNav
                bg="white"
                theme="light"
                containerClass="!px-0"
                className="py-[0px] md:py-[18px] md:px-[15px] sm:px-0"
              >
                <Col className="md:flex md:justify-end md:px-[15px]">
                  <Navbar.Toggle
                    className="order-last md:ml-[17px]"
                    onClick={() => {
                      console.log("Clicked2!");
                    }}
                  >
                    <span className="navbar-toggler-line"></span>
                    <span className="navbar-toggler-line"></span>
                    <span className="navbar-toggler-line"></span>
                    <span className="navbar-toggler-line"></span>
                  </Navbar.Toggle>
                  <div className="hidden md:block">
                    <Navbar.Collapse
                      className="col-auto justify-center"
                      onClick={() => {
                        console.log("Clicked!");
                      }}
                    >
                      <Menu {...props} />
                    </Navbar.Collapse>
                  </div>
                  <div className="flex justify-between md:hidden col-lg-9 col-xl-8 ">
                    <Menu
                      data={HeaderData.slice(0, Math.floor(HeaderData.length))}
                    />
                  </div>
                </Col>

                <Col className="col-auto none sm:block text-end lg:px-0 hide-on-small-screen">
                  <span className="top-bar-contact-list border-l border-inherit	py-[9px] px-[18px] text-[13px] inline-block float-left">
                    <i className="feather-phone-call text-darkgray mr-[6px] text-md relative top-[1px]"></i>{" "}
                    213 992 9337
                  </span>
                  <span className="border-l border-inherit py-[9px] pl-[18px] text-[13px] inline-block float-left border-r-0 pr-0">
                    <i className="feather-map-pin text-darkgray mr-[6px] text-md relative top-[1px]"></i>{" "}
                    8 The Green STE 11673, Dover Delaware, 19904
                  </span>
                </Col>
              </HeaderNav>
            </Row>
          </Container>
        </Header>
      </div>

      {/* Header End */}
      {/* Section Start */}
      <section
        id="about"
        className="bg-lightgray py-[150px] lg:py-[150px] md:py-[100px] sm:py-[50px]"
      >
        <Container style={{ fontSize: 20 }}>
          <h5 className="w-full mb-[20px] font-semibold text-darkgray font-serif -tracking-[1px]">
            About Us
          </h5>
          <Row className="font-serif">
            <Col lg={4} className="pe-lg-0 flex md:mb-[30px]">
              <div
                className="w-full md:h-[700px] sm:h-[550px] xs:h-[450px] cover-background"
                style={{
                  backgroundImage:
                    "url(/assets/img/webp/coupletalking-transformed.jpeg)",
                }}
              ></div>
            </Col>
            <Col
              lg={4}
              md={6}
              className="ps-lg-0 flex items-center sm:mb-[30px]"
            >
              <div className="justify-center h-full w-full flex flex-col items-start bg-fastblue px-[5.5rem] lg:px-[3rem] md:p-16">
                <span className="text-xlg lg:text-lg lg:leading-[26px] font-medium text-white mb-[20px] block">
                  An easy and stress-free move is possible
                </span>
                <p className="text-white font-sans opacity-70 mb-[20px] xs:mb-[15px]">
                  We began our journey to revolutionize the moving brokerage
                  industry with two employees and a desire to become the best.
                  After working for multiple companies, we opened our own with
                  all the knowledge of what is right and wrong.
                </p>
              </div>
            </Col>
            <Col lg={4} md={6} className="flex flex-col pr-0">
              <img
                src="/assets/img/webp/carryingboxesstairs-transformed(1).jpeg"
                alt="about us"
                className="sm:w-full"
              />
              <div className="bg-white px-[3.5rem] py-[3rem] h-full lg:p-8 sm:p-16">
                <span className="text-darkgray font-medium mb-[10px] block">
                  A single-minded focus on getting results
                </span>
                <p className="font-sans">
                  A majority of our business comes from word of mouth and
                  customer testimonials. We want to hear your feedback once your
                  move has been completed!
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <section className="bg-[#f7f8fc] py-[80px]">
        <Container>
          <Row className="justify-center">
            <m.div
              className="col-xl-6 col-lg-7 col-sm-8 mb-20 text-center w-[51%] xl:mb-[70px] lg:mb-[65px] md:mb-[60px] sm:mb-[55px] md:w-[68%] xs:w-full"
              {...fadeIn}
            ></m.div>
          </Row>
          <Row className="justify-center">
            <Col lg={12} md={9} xs={12}>
              <IconWithText
                grid="row-cols-1 row-cols-lg-2 gap-y-10 justify-center"
                theme="icon-with-text-02"
                data={IconWithTextData_02}
                animation={fadeIn}
                animationDelay={0.2}
              />
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Footer Start */}
      <FooterStyle01 theme="dark" className="bg-[#262b35] text-slateblue" />
      {/* Footer End */}
    </div>
  );
};

export default AboutUs;
