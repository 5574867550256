import React, { useRef, useEffect, useState } from "react";

// Libraries
import { sendEmail } from "../Functions/Utilities";
import { Formik, Form } from "formik";
import { Col, Container, Row, Navbar } from "react-bootstrap";
import { Menu } from "../Components/Header/Header";
import HeaderData from "../Components/Header/HeaderData";
import Header from "../Components/Header/Header";
import { HeaderNav } from "../Components/Header/Header";
// Components
import Buttons from "../Components/Button/Buttons";
import FooterStyle01 from "../Components/Footers/FooterStyle01";
import { Input, TextArea } from "../Components/Form/Form";
import { ContactFormStyle02Schema } from "../Components/Form/FormSchema";
import SideButtons from "../Components/SideButtons";
import { resetForm } from "../Functions/Utilities";
import { Paper } from "@mui/material";

const PayDeposit = (props) => {
  return (
    <div
      className="checkout-page"
      style={{
        ...props.style,
        backgroundImage: "url(/assets/img/webp/bg3.png)",
      }}
    >
      <SideButtons />
      {/* Header Start */}
      <div
        style={{
          position: "fixed",
          top: "0",
          width: "100%",
          background: "white",
          textAlign: "center",
          zIndex: 1000, // To ensure it appears above all other content
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Optional: for a shadow effect
        }}
      >
        <Header>
          <Container fluid>
            <Row className="justify-between pl-[15px] lg:pr-[15px] items-center">
              <HeaderNav
                bg="white"
                theme="light"
                containerClass="!px-0"
                className="py-[0px] md:py-[18px] md:px-[15px] sm:px-0"
              >
                <Col className="md:flex md:justify-end md:px-[15px]">
                  <Navbar.Toggle
                    className="order-last md:ml-[17px]"
                    onClick={() => {
                      console.log("Clicked2!");
                    }}
                  >
                    <span className="navbar-toggler-line"></span>
                    <span className="navbar-toggler-line"></span>
                    <span className="navbar-toggler-line"></span>
                    <span className="navbar-toggler-line"></span>
                  </Navbar.Toggle>
                  <div className="hidden md:block">
                    <Navbar.Collapse
                      className="col-auto justify-center"
                      onClick={() => {
                        console.log("Clicked!");
                      }}
                    >
                      <Menu {...props} />
                    </Navbar.Collapse>
                  </div>
                  <div className="flex justify-between md:hidden col-lg-9 col-xl-8 ">
                    <Menu
                      data={HeaderData.slice(0, Math.floor(HeaderData.length))}
                    />
                  </div>
                </Col>

                <Col className="col-auto none sm:block text-end lg:px-0 hide-on-small-screen">
                  <span className="top-bar-contact-list border-l border-inherit	py-[9px] px-[18px] text-[13px] inline-block float-left">
                    <i className="feather-phone-call text-darkgray mr-[6px] text-md relative top-[1px]"></i>{" "}
                    213 992 9337
                  </span>
                  <span className="border-l border-inherit py-[9px] pl-[18px] text-[13px] inline-block float-left border-r-0 pr-0">
                    <i className="feather-map-pin text-darkgray mr-[6px] text-md relative top-[1px]"></i>{" "}
                    8 The Green STE 11673, Dover Delaware, 19904
                  </span>
                </Col>
              </HeaderNav>
            </Row>
          </Container>
        </Header>
      </div>

      {/* Header End */}

      {/* Section End */}
      {/* Section Start */}
      <section
        className="pb-[130px] lg:pb-[90px] md:pb-[75px] sm:pb-[50px]"
        style={{
          backgroundImage: "url(/assets/img/webp/bg3.png)",
          marginLeft: "6%",
          marginRight: "6%",
          marginTop: 50,
        }}
      >
        <Paper
          style={{
            padding: "5%",
            backgroundColor: "lightblue",
          }}
        >
          <Container>
            <Row>
              <Col
                lg={12}
                md={12}
                className="pr-[70px] lg:pr-[40px] md:pr-[15px]"
              >
                <span className="font-serif text-lg md:text-xmd text-darkgray mb-[40px] inline-block font-medium sm:text-xmd">
                  Billing details
                </span>
                <Formik
                  initialValues={{
                    name: "",
                    bankname: "",
                    check: "",
                    routing: "",
                    account: "",
                    address: "",
                    jobnumber: "",
                    email: "",
                    comment: "",
                    subject: "*Sensitive* PMG Bill Pay Request",
                  }}
                  validationSchema={ContactFormStyle02Schema}
                  onSubmit={async (values, actions) => {
                    console.log("Submmitted!");
                    actions.setSubmitting(true);
                    const response = await sendEmail(values);
                    response.status === "success" && resetForm(actions);
                  }}
                >
                  {({ isSubmitting, errors }) => (
                    <Form>
                      <Row>
                        <Col md={6}>
                          <label className="mb-[15px]">
                            Name on Account{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <Input
                            showErrorMsg={true}
                            name="name"
                            type="text"
                            className="py-[13px] px-[15px] w-full border-[1px] text-md mb-[15px] leading-[initial] border-solid border-[#dfdfdf]"
                            placeholder=""
                            labelClass="mb-[10px]"
                          />
                        </Col>
                        <Col md={6}>
                          <label className="mb-[15px]">
                            Name of Bank <span className="text-red-500">*</span>
                          </label>
                          <Input
                            showErrorMsg={true}
                            name="bankname"
                            type="text"
                            className="py-[13px] px-[15px] w-full border-[1px] text-md mb-[15px] leading-[initial] border-solid border-[#dfdfdf]"
                            placeholder=""
                            labelClass="mb-[10px]"
                          />
                        </Col>
                        <Col className="col-12">
                          <label className="mb-[15px]">Check Number</label>
                          <Input
                            showErrorMsg={true}
                            name="check"
                            type="text"
                            className="py-[13px] px-[15px] w-full border-[1px] text-md mb-[15px] leading-[initial] border-solid border-[#dfdfdf]"
                            placeholder=""
                            labelClass="mb-[10px]"
                          />
                        </Col>
                        <Col md={6}>
                          <label className="mb-[15px]">
                            Routing Number{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <Input
                            showErrorMsg={true}
                            name="routing"
                            type="text"
                            className="py-[13px] px-[15px] w-full border-[1px] text-md mb-[15px] leading-[initial] border-solid border-[#dfdfdf]"
                            placeholder=""
                            labelClass="mb-[10px]"
                          />
                        </Col>
                        <Col md={6}>
                          <label className="mb-[15px]">
                            Account Number{" "}
                            <span className="text-red-500">*</span>
                          </label>

                          <Input
                            showErrorMsg={true}
                            name="account"
                            type="text"
                            className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]"
                            placeholder=""
                            labelClass="mb-[15px]"
                          />
                        </Col>
                        <Col className="col-12 mb-[10px]">
                          <label className="mb-[15px]">
                            Billing Address{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <Input
                            showErrorMsg={true}
                            name="address"
                            type="text"
                            className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]"
                            placeholder=""
                            labelClass="mb-[15px]"
                          />
                        </Col>

                        <Col md={6}>
                          <label className="mb-[15px]">
                            Job Number <span className="text-red-500">*</span>
                          </label>
                          <Input
                            showErrorMsg={true}
                            name="jobnumber"
                            type="text"
                            className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf]"
                            placeholder=""
                            labelClass="mb-[15px]"
                          />
                        </Col>

                        <Col md={6}>
                          <label className="mb-[15px]">
                            Email address{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <Input
                            showErrorMsg={true}
                            name="email"
                            type="email"
                            className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf]"
                            placeholder=""
                            labelClass="mb-[15px]"
                          />
                        </Col>

                        <Col className="col-12 mt-[15px]">
                          <label className="mb-[15px]">
                            Additional notes (optional)
                          </label>
                          <TextArea
                            className="border-[1px] border-solid border-[#dfdfdf] w-full py-[15px] px-[20px] text-md h-[210px] resize-none"
                            rows="5"
                            columns="5"
                            name="comment"
                            placeholder="Put any additional comments here"
                          ></TextArea>
                        </Col>
                        <Col className="text-right sm:text-center">
                          <Buttons
                            type="submit"
                            className={`text-xs tracking-[1px] rounded-none py-[12px] px-[28px] uppercase${
                              isSubmitting ? " loading" : ""
                            }`}
                            themeColor={["#b884fd", "#fe73a8", "b884fd"]}
                            size="md"
                            color="#fff"
                            title="Submit Payment"
                          />
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Col>
              <Col className="col-12 mt-[15px]"></Col>
            </Row>
          </Container>
        </Paper>
      </section>
      {/* Section End */}

      {/* Footer Start */}
      <FooterStyle01 theme="dark" className="text-slateblue bg-[#262b35]" />
      {/* Footer End */}
    </div>
  );
};

export default PayDeposit;
